import * as i0 from '@angular/core';
import { InjectionToken, inject, ElementRef, Directive, Injectable, NgModule } from '@angular/core';
import { Observable, share } from 'rxjs';
import { WA_WINDOW } from '@ng-web-apis/common';
const SafeObserver = typeof IntersectionObserver !== 'undefined' ? IntersectionObserver : class {
  root = null;
  rootMargin = '';
  thresholds = [];
  observe() {}
  unobserve() {}
  disconnect() {}
  takeRecords() {
    return [];
  }
};
const WA_INTERSECTION_ROOT = new InjectionToken('[WA_INTERSECTION_ROOT]');
/**
 * @deprecated: drop in v5.0, use {@link WA_INTERSECTION_ROOT}
 */
const INTERSECTION_ROOT = WA_INTERSECTION_ROOT;
const WA_INTERSECTION_ROOT_MARGIN_DEFAULT = '0px 0px 0px 0px';
/**
 * @deprecated: drop in v5.0, use {@link WA_INTERSECTION_ROOT_MARGIN_DEFAULT}
 */
const INTERSECTION_ROOT_MARGIN_DEFAULT = WA_INTERSECTION_ROOT_MARGIN_DEFAULT;
const WA_INTERSECTION_ROOT_MARGIN = new InjectionToken('[WA_INTERSECTION_ROOT_MARGIN]', {
  providedIn: 'root',
  factory: () => INTERSECTION_ROOT_MARGIN_DEFAULT
});
/**
 * @deprecated: drop in v5.0, use {@link WA_INTERSECTION_ROOT_MARGIN}
 */
const INTERSECTION_ROOT_MARGIN = WA_INTERSECTION_ROOT_MARGIN;
function rootMarginFactory() {
  return inject(ElementRef).nativeElement.getAttribute('waIntersectionRootMargin') || INTERSECTION_ROOT_MARGIN_DEFAULT;
}
const WA_INTERSECTION_THRESHOLD_DEFAULT = 0;
/**
 * @deprecated: drop in v5.0, use {@link WA_INTERSECTION_THRESHOLD_DEFAULT}
 */
const INTERSECTION_THRESHOLD_DEFAULT = WA_INTERSECTION_THRESHOLD_DEFAULT;
const WA_INTERSECTION_THRESHOLD = new InjectionToken('[WA_INTERSECTION_THRESHOLD]', {
  providedIn: 'root',
  factory: () => INTERSECTION_THRESHOLD_DEFAULT
});
/**
 * @deprecated: drop in v5.0, use {@link WA_INTERSECTION_THRESHOLD}
 */
const INTERSECTION_THRESHOLD = WA_INTERSECTION_THRESHOLD;
function thresholdFactory() {
  return inject(ElementRef).nativeElement.getAttribute('waIntersectionThreshold')?.split(',').map(parseFloat) || INTERSECTION_THRESHOLD_DEFAULT;
}
class WaIntersectionObserverDirective extends SafeObserver {
  callbacks = new Map();
  margin = '';
  threshold = '';
  constructor() {
    const root = inject(INTERSECTION_ROOT, {
      optional: true
    });
    super(entries => {
      this.callbacks.forEach((callback, element) => {
        const filtered = entries.filter(({
          target
        }) => target === element);
        return filtered.length && callback(filtered, this);
      });
    }, {
      root: root?.nativeElement,
      rootMargin: rootMarginFactory(),
      threshold: thresholdFactory()
    });
  }
  observe(target, callback = () => {}) {
    super.observe(target);
    this.callbacks.set(target, callback);
  }
  unobserve(target) {
    super.unobserve(target);
    this.callbacks.delete(target);
  }
  ngOnDestroy() {
    this.disconnect();
  }
  static ɵfac = function WaIntersectionObserverDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WaIntersectionObserverDirective)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: WaIntersectionObserverDirective,
    selectors: [["", "waIntersectionObserver", ""]],
    inputs: {
      margin: [0, "waIntersectionRootMargin", "margin"],
      threshold: [0, "waIntersectionThreshold", "threshold"]
    },
    exportAs: ["IntersectionObserver"],
    standalone: true,
    features: [i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WaIntersectionObserverDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[waIntersectionObserver]',
      inputs: ['margin: waIntersectionRootMargin', 'threshold: waIntersectionThreshold'],
      exportAs: 'IntersectionObserver'
    }]
  }], function () {
    return [];
  }, null);
})();
/**
 * @deprecated: use {@link WaIntersectionObserverDirective}
 */
const IntersectionObserverDirective = WaIntersectionObserverDirective;
/**
 * @deprecated: use {@link WaIntersectionObserver}
 */
const WaObserver = WaIntersectionObserverDirective;
class IntersectionObserveeService extends Observable {
  constructor() {
    const nativeElement = inject(ElementRef).nativeElement;
    const observer = inject(WaIntersectionObserverDirective);
    super(subscriber => {
      observer.observe(nativeElement, entries => {
        subscriber.next(entries);
      });
      return () => {
        observer.unobserve(nativeElement);
      };
    });
    return this.pipe(share());
  }
  static ɵfac = function IntersectionObserveeService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || IntersectionObserveeService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: IntersectionObserveeService,
    factory: IntersectionObserveeService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionObserveeService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class WaIntersectionObservee {
  waIntersectionObservee = inject(IntersectionObserveeService);
  static ɵfac = function WaIntersectionObservee_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WaIntersectionObservee)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: WaIntersectionObservee,
    selectors: [["", "waIntersectionObservee", ""]],
    outputs: {
      waIntersectionObservee: "waIntersectionObservee"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([IntersectionObserveeService])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WaIntersectionObservee, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[waIntersectionObservee]',
      outputs: ['waIntersectionObservee'],
      providers: [IntersectionObserveeService]
    }]
  }], null, null);
})();
/**
 * @deprecated: use {@link WaIntersectionObservee}
 */
const IntersectionObserveeDirective = WaIntersectionObservee;
/**
 * @deprecated: use {@link WaIntersectionObservee}
 */
const WaObservee = WaIntersectionObservee;
class WaIntersectionRoot {
  static ɵfac = function WaIntersectionRoot_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WaIntersectionRoot)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: WaIntersectionRoot,
    selectors: [["", "waIntersectionRoot", ""]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([{
      provide: INTERSECTION_ROOT,
      useExisting: ElementRef
    }])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WaIntersectionRoot, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[waIntersectionRoot]',
      providers: [{
        provide: INTERSECTION_ROOT,
        useExisting: ElementRef
      }]
    }]
  }], null, null);
})();
/**
 * @deprecated: use {@link WaIntersectionRoot}
 */
const IntersectionRootDirective = WaIntersectionRoot;
const WaIntersectionObserver = [WaIntersectionObserverDirective, WaIntersectionObservee, WaIntersectionRoot];
/**
 * @deprecated: use {@link WaIntersectionObserver}
 */
class IntersectionObserverModule {
  static ɵfac = function IntersectionObserverModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || IntersectionObserverModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: IntersectionObserverModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionObserverModule, [{
    type: NgModule,
    args: [{
      imports: [WaIntersectionObserverDirective, WaIntersectionObservee, WaIntersectionRoot],
      exports: [WaIntersectionObserverDirective, WaIntersectionObservee, WaIntersectionRoot]
    }]
  }], null, null);
})();
class IntersectionObserverService extends Observable {
  nativeElement = inject(ElementRef).nativeElement;
  rootMargin = inject(INTERSECTION_ROOT_MARGIN);
  threshold = inject(INTERSECTION_THRESHOLD);
  root = inject(INTERSECTION_ROOT, {
    optional: true
  })?.nativeElement ?? null;
  constructor() {
    super(subscriber => {
      const observer = new SafeObserver(entries => {
        subscriber.next(entries);
      }, {
        root: this.root,
        rootMargin: this.rootMargin,
        threshold: this.threshold
      });
      observer.observe(this.nativeElement);
      return () => {
        observer.disconnect();
      };
    });
  }
  static ɵfac = function IntersectionObserverService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || IntersectionObserverService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: IntersectionObserverService,
    factory: IntersectionObserverService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionObserverService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
const WA_INTERSECTION_OBSERVER_SUPPORT = new InjectionToken('[WA_INTERSECTION_OBSERVER_SUPPORT]: [INTERSECTION_OBSERVER_SUPPORT]', {
  providedIn: 'root',
  factory: () => !!inject(WA_WINDOW).IntersectionObserver
});
/**
 * @deprecated: drop in v5.0, use {@link WA_INTERSECTION_OBSERVER_SUPPORT}
 */
const INTERSECTION_OBSERVER_SUPPORT = WA_INTERSECTION_OBSERVER_SUPPORT;

/**
 * Generated bundle index. Do not edit.
 */

export { INTERSECTION_OBSERVER_SUPPORT, INTERSECTION_ROOT, INTERSECTION_ROOT_MARGIN, INTERSECTION_ROOT_MARGIN_DEFAULT, INTERSECTION_THRESHOLD, INTERSECTION_THRESHOLD_DEFAULT, IntersectionObserveeDirective, IntersectionObserveeService, IntersectionObserverDirective, IntersectionObserverModule, IntersectionObserverService, IntersectionRootDirective, WA_INTERSECTION_OBSERVER_SUPPORT, WA_INTERSECTION_ROOT, WA_INTERSECTION_ROOT_MARGIN, WA_INTERSECTION_ROOT_MARGIN_DEFAULT, WA_INTERSECTION_THRESHOLD, WA_INTERSECTION_THRESHOLD_DEFAULT, WaIntersectionObservee, WaIntersectionObserver, WaIntersectionObserverDirective, WaIntersectionRoot, WaObservee, WaObserver };
